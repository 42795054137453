/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Crimson+Text:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./assets/theme/font";
@import "./assets/theme/klaro";

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 18px;
}

html, body {
  height: 100%;
}

.mat-menu-panel {
  background-color: #000 !important;
}

