#klaro {
  font-family: Roboto Mono, monospace;

  .klaro .cookie-modal .cm-btn.cm-btn-success, .klaro .cookie-notice .cm-btn.cm-btn-success {
    background: #fff;
    color: #000;
    font-size: .75rem;
    padding: 0.25rem .75rem;
    border-radius: 99999px;
  }

  .klaro .cookie-modal .cm-btn.cm-btn-danger, .klaro .cookie-notice .cm-btn.cm-btn-danger {
    background: #005091;
    font-size: .75rem;
    padding: 0.25rem .75rem;
    border-radius: 99999px;
  }
}
