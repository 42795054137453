@font-face {
  font-family: 'CaslonGraphiqueEF-Regular';
  font-display: swap;
  src: local('3A73A1_0_0'), local('3A73A1_0_0'),
  url('~assets/theme/3A73A1_0_0.woff') format('woff');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('~assets/theme/material.woff2') format('woff2');
}
