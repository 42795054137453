/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Crimson+Text:400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "CaslonGraphiqueEF-Regular";
  font-display: swap;
  src: local("3A73A1_0_0"), local("3A73A1_0_0"), url("~assets/theme/3A73A1_0_0.woff") format("woff");
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("~assets/theme/material.woff2") format("woff2");
}
#klaro {
  font-family: Roboto Mono, monospace;
}
#klaro .klaro .cookie-modal .cm-btn.cm-btn-success, #klaro .klaro .cookie-notice .cm-btn.cm-btn-success {
  background: #fff;
  color: #000;
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
  border-radius: 99999px;
}
#klaro .klaro .cookie-modal .cm-btn.cm-btn-danger, #klaro .klaro .cookie-notice .cm-btn.cm-btn-danger {
  background: #005091;
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
  border-radius: 99999px;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 18px;
}

html, body {
  height: 100%;
}

.mat-menu-panel {
  background-color: #000 !important;
}